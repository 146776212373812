<template>
  <div class="wrapper">
    <video
      :class="['fire', isTokenFlipEnded ? 'show-video' : 'hide-video']"
      autoplay
      muted
      loop
    >
      <source src="../../assets/fire-2.mp4" type="video/mp4" />
    </video>
    <template v-if="revealedHero">
      <div class="revealed-hero">
        <div class="image">
          <img :src="revealedHero.image" :alt="revealedHero.name" />
        </div>
        <div class="meta name">
          {{ revealedHero.name }}
        </div>
        <div
          class="meta"
          :key="index"
          v-for="(attribute, index) in revealedHero.attributes"
        >
          <span class="key">
            {{ attribute.trait_type }}
          </span>
          <span
            :class="[
              'value',
              attribute.trait_type === 'Rank'
                ? attribute.value.toLowerCase()
                : '',
            ]"
          >
            {{ attribute.value }}
          </span>
        </div>
      </div>
      <div class="controls">
        <div class="congrats-header">
          <h4>Good luck, brave hero, in the land of Dragon Haven!</h4>
        </div>
        <div class="buttons">
          <BlueButton
            @click="goToGame"
            size="small"
            class="play-button"
            :isLoading="isLoading"
            >Play</BlueButton
          >
          <BlueButton
            @click="refresh"
            size="small"
            class="continue-button"
            :isLoading="isLoading"
            >Reveal Another</BlueButton
          >
        </div>
      </div>
    </template>
    <div>
      <h3 :class="['title', isBurningToken ? 'hide' : '']">
        Redeem Your Heros from Distant Lands Token
      </h3>
      <GenericLoading v-if="isLoadingInitialData" />
      <template v-else>
        <template v-if="!isSessionActive">
          <span class="connected-as" v-if="address"
            >Connected As {{ address.substr(0, 4) }}...{{
              address.substr(address.length - 4, address.length)
            }}</span
          >
          <ConnectButton size="large" />
        </template>
        <template v-else>
          <BlueButton
            @click="approveAll"
            class="reveal-button"
            :isLoading="isLoading"
            v-if="!isTokenApproved"
            >Approve Token</BlueButton
          >
          <BlueButton
            v-else
            :class="['reveal-button', isBurningToken ? 'hide' : '']"
            @click="exchangeToken(redeemTokenIds[0])"
            :isLoading="isLoading || isBurningToken"
            :disabled="
              redeemTokenIds.length === 0 || isLoading || isBurningToken
            "
            >Reveal</BlueButton
          >
          <div class="reveal-container" ref="revealButton">
            <span
              :class="['count', isBurningToken ? 'hide' : '']"
              v-if="!isTokenFlipEnded"
              >&times; {{ redeemTokenIds.length }}
              {{ redeemTokenIds.length > 1 ? "Tokens" : "Token" }}</span
            >
            <div :class="['coin-wrapper', !isBurningToken ? 'bounce' : '']">
              <div :class="['coin']" ref="coin">
                <div class="coin__middle"></div>
                <div class="coin__back"></div>
                <div class="coin__front"></div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
  <Notifications />
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useSound } from "../../composables/sound";
import coinFlip from "../../assets/sound/coin-flip.mp3";
import fireMagic from "../../assets/sound/fire-magic.mp3";
import drums from "../../assets/sound/drums.mp3";

import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import Notifications from "../../components/Notifications.vue";
import ConnectButton from "../../components/Buttons/ConnectButton.vue";
import BlueButton from "../../components/Buttons/BlueButton.vue";

import { ethers } from "ethers";
import { parseError } from "../../utils/helpers";
import {
  getDCGHeroesRedeemTokenContract,
  getDCGDistantLandsHeroExchange,
  getNFTFinderContract,
} from "../../utils/getContract";
import { useUser } from "../../composables/user";
export default {
  displayName: "Reveal",
  components: {
    BlueButton,
    ConnectButton,
    Notifications,
    GenericLoading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const revealButton = ref(null);
    const coin = ref(null);
    const maxMoveLoopCount = ref(90);
    const isLoading = ref(false);
    const isLoadingInitialData = ref(true);
    const isBurningToken = ref(false);
    const isTokenFlipEnded = ref(false);
    const { address, signer } = useUser();
    const revealedHero = ref(null);

    const redeemTokenIds = ref([]);
    const isTokenApproved = ref(false);

    const RedeemTokenContract = ref(null);
    const DCGTokenHeroExchangeContract = ref(null);
    const NFTFinder = ref(null);

    onMounted(async () => {
      // Doubling animation frame count if it's a non retina display
      if (window.devicePixelRatio <= 1) {
        maxMoveLoopCount.value *= 2;
      }
      /**
       * Preloading audio file for a more seamless experience
       */
      const audioCoinFlip = new Audio(coinFlip);
      const audioFireMagic = new Audio(fireMagic);
      const audioDrums = new Audio(drums);
      audioCoinFlip.load();
      audioFireMagic.load();
      audioDrums.load();

      isLoadingInitialData.value = true;
      if (isSessionActive.value) {
        RedeemTokenContract.value = getDCGHeroesRedeemTokenContract(
          signer.value
        );
        DCGTokenHeroExchangeContract.value = getDCGDistantLandsHeroExchange(
          signer.value
        );
        NFTFinder.value = getNFTFinderContract(signer.value);
        await getRedeemTokens();
        await checkIsApprovedForAll();
      }
      isLoadingInitialData.value = false;
    });

    onUnmounted(() => {
      unWatchSessionActive();
    });

    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          isLoadingInitialData.value = true;
          RedeemTokenContract.value = getDCGHeroesRedeemTokenContract(
            signer.value
          );
          DCGTokenHeroExchangeContract.value = getDCGDistantLandsHeroExchange(
            signer.value
          );
          NFTFinder.value = getNFTFinderContract(signer.value);
          await getRedeemTokens();
          await checkIsApprovedForAll();
          isLoadingInitialData.value = false;
        }
      }
    );

    const goToGame = () => {
      router.push("/");
    };
    const refresh = () => {
      window.location.reload();
    };

    const getRedeemTokens = async () => {
      const balance = ethers.utils.formatUnits(
        await RedeemTokenContract.value.balanceOf(address.value),
        0
      );
      let nftArray = [];
      if (balance > 0) {
        if (
          address.value.toLowerCase() ==
          "0xC7d75cCDAD8C368382e490FFd64B7ee2c2a0BB62".toLowerCase()
        ) {
          redeemTokenIds.value = [0];
        } else {
          nftArray = await NFTFinder.value.findTokens(
            RedeemTokenContract.value.address,
            address.value
          );
          redeemTokenIds.value = await Promise.all(nftArray);
          redeemTokenIds.value.map((item, index) => {
            redeemTokenIds.value[index] = ethers.utils.formatUnits(item, 0);
          });
        }
      }
    };

    const checkIsApprovedForAll = async () => {
      const result = await RedeemTokenContract.value.isApprovedForAll(
        address.value,
        DCGTokenHeroExchangeContract.value.address
      );
      isTokenApproved.value = result;
    };

    const approveAll = async () => {
      try {
        isLoading.value = true;
        const approveTokenTx =
          await RedeemTokenContract.value.setApprovalForAll(
            DCGTokenHeroExchangeContract.value.address,
            true
          );
        const receiptOfApproval = await approveTokenTx.wait();

        // If marketplace contract not approved to transfer the token id
        if (receiptOfApproval.status !== 1) {
          isLoading.value = false;
          return;
        }
        isTokenApproved.value = true;
        isLoading.value = false;
      } catch (error) {
        store.commit("setNotification", parseError(error));
        isLoading.value = false;
      }
    };

    const exchangeToken = async (id) => {
      console.log("TOKEN ID Burning", id);
      try {
        isLoading.value = true;
        const exchangeTx = await DCGTokenHeroExchangeContract.value.exchange(
          id
        );
        const receiptOfExchange = await exchangeTx.wait();
        if (receiptOfExchange.status === 1) {
          // console.log(receiptOfExchange);
          const filteredEvent = receiptOfExchange.events.filter((item) => {
            return item.event === "TokenExchanged";
          });
          const exchangeEvent = filteredEvent[0];
          const heroId = ethers.utils.formatUnits(
            exchangeEvent.args.receivedHeroTokenId,
            0
          );
          await delay(3000);
          const response = await fetch(
            // `http://127.0.0.1:439/metadata/${heroId}`,
            `https://heroes.dragoncrypto.io/metadata/${heroId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const result = await response.json();
          const parts = result.image.split("/");
          result.image = `https://ik.imagekit.io/dcg/${
            parts[parts.length - 1]
          }?tr=w-300`;
          result.attributes = result.attributes.filter(
            (item) => item.trait_type !== "Level"
          );
          await processTokenBurnFlow();
          await delay(2500);
          revealedHero.value = result;
        }
        isLoading.value = false;
      } catch (error) {
        console.log(error);
        store.commit(
          "setNotification",
          "Error in exchanging token. Please try again."
        );
        isLoading.value = false;
      }
    };

    // Loop through all buttons (allows for multiple buttons on page)

    // The larger the number, the slower the animation
    // maxMoveLoopCount = 90;

    const processTokenBurnFlow = async () => {
      if (revealButton.value.clicked) return;
      // isLoading.value = true;
      // await delay(2000);
      // isLoading.value = false;

      revealButton.value.classList.add("clicked");
      isBurningToken.value = true;
      playOnFlip.play();

      // Wait to start flipping the coin because of the button tilt animation
      coin.value.sideRotationCount = Math.floor(Math.random() * 8) * 90;
      coin.value.maxFlipAngle = (Math.floor(Math.random() * 4) + 3) * Math.PI;
      revealButton.value.clicked = true;
      flipCoin();
      await delay(1500);
      playFireMagic.play();

      //   setTimeout(async () => {
      //     // Randomize the flipping speeds just for fun
      //     coin.value.sideRotationCount = Math.floor(Math.random() * 8) * 90;
      //     coin.value.maxFlipAngle = (Math.floor(Math.random() * 4) + 3) * Math.PI;
      //     revealButton.value.clicked = true;
      //     flipCoin();
      //     await delay(1500);
      //     playFireMagic.play();
      //   }, 50);
    };

    const flipCoin = () => {
      coin.value.moveLoopCount = 0;
      flipCoinLoop();
    };

    const playOnFlip = useSound(coinFlip, {
      volume: store.state.soundVolume,
      interrupt: true,
    });

    const playFireMagic = useSound(fireMagic, {
      volume: store.state.soundVolume,
      interrupt: true,
    });
    const playDrums = useSound(drums, {
      volume: store.state.soundVolume,
      interrupt: true,
    });
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    // const resetCoin = () => {
    //   coin.value.style.setProperty("--coin-x-multiplier", 0);
    //   coin.value.style.setProperty("--coin-y-multiplier", 0);
    //   coin.value.style.setProperty("--coin-scale-multiplier", 0);
    //   coin.value.style.setProperty("--coin-rotation-multiplier", 0);
    //   coin.value.style.setProperty("--shine-opacity-multiplier", 0.4);
    //   coin.value.style.setProperty("--shine-bg-multiplier", "50%");
    //   coin.value.style.setProperty("opacity", 1);
    //   // Delay to give the reset animation some time before you can click again
    //   setTimeout(() => {
    //     revealButton.value.clicked = false;
    //   }, 300);
    // };

    const flipCoinLoop = async () => {
      coin.value.moveLoopCount++;
      let percentageCompleted =
        coin.value.moveLoopCount / maxMoveLoopCount.value;
      coin.value.angle =
        -coin.value.maxFlipAngle * Math.pow(percentageCompleted - 1, 2) +
        coin.value.maxFlipAngle;

      // Calculate the scale and position of the coin moving through the air
      const yMultiplier = -11 * Math.pow(percentageCompleted * 2 - 1, 4) + 15;
      coin.value.style.setProperty("--coin-y-multiplier", yMultiplier);
      coin.value.style.setProperty("--coin-x-multiplier", 0);
      //   coin.value.style.setProperty("--coin-x-multiplier", percentageCompleted);
      coin.value.style.setProperty(
        "--coin-scale-multiplier",
        percentageCompleted * 0.6
      );
      const rotationValue = percentageCompleted * coin.value.sideRotationCount;
      coin.value.style.setProperty("--coin-rotation-multiplier", rotationValue);

      // Calculate the scale and position values for the different coin faces
      // The math uses sin/cos wave functions to similate the circular motion of 3D spin
      coin.value.style.setProperty(
        "--front-scale-multiplier",
        Math.max(Math.cos(coin.value.angle), 0)
      );
      coin.value.style.setProperty(
        "--front-y-multiplier",
        Math.sin(coin.value.angle)
      );

      coin.value.style.setProperty(
        "--middle-scale-multiplier",
        Math.abs(Math.cos(coin.value.angle), 0)
      );
      coin.value.style.setProperty(
        "--middle-y-multiplier",
        Math.cos((coin.value.angle + Math.PI / 2) % Math.PI)
      );

      coin.value.style.setProperty(
        "--back-scale-multiplier",
        Math.max(Math.cos(coin.value.angle - Math.PI), 0)
      );
      coin.value.style.setProperty(
        "--back-y-multiplier",
        Math.sin(coin.value.angle - Math.PI)
      );

      coin.value.style.setProperty(
        "--shine-opacity-multiplier",
        4 * Math.sin((coin.value.angle + Math.PI / 2) % Math.PI) - 3.2
      );
      coin.value.style.setProperty(
        "--shine-bg-multiplier",
        -40 * (Math.cos((coin.value.angle + Math.PI / 2) % Math.PI) - 0.5) + "%"
      );

      // Repeat animation loop
      if (coin.value.moveLoopCount < maxMoveLoopCount.value) {
        if (coin.value.moveLoopCount === maxMoveLoopCount.value - 6)
          revealButton.value.classList.add("shrink-landing");
        window.requestAnimationFrame(flipCoinLoop);
      } else {
        revealButton.value.classList.add("coin-landed");
        coin.value.style.setProperty("opacity", 0);
        await delay(1500);

        isTokenFlipEnded.value = true;
        playDrums.play();
        // setTimeout(() => {
        //   revealButton.value.classList.remove(
        //     "clicked",
        //     "shrink-landing",
        //     "coin-landed"
        //   );
        //   //   setTimeout(() => {
        //   //     resetCoin();
        //   //   }, 300);
        // }, 1500);
      }
    };

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    return {
      // data
      coin,
      revealButton,
      isLoading,
      isBurningToken,
      isTokenFlipEnded,
      isTokenApproved,
      redeemTokenIds,
      revealedHero,
      isLoadingInitialData,
      address,
      // method
      exchangeToken,
      approveAll,
      refresh,
      goToGame,
      // computed
      isSessionActive,
    };
  },
};
</script>
<style lang="scss" scoped>
/* $coin-size: 12rem; */
$coin-size: 198px;
$coin-thickness: $coin-size / 11;

$bg: #f4f7ff;
$bg-button: #000000;
$font-color: #fff;
$c-l: #fcfaf9;
$c-m: #c2cadf;
$c-d: #4f470e;
$c-side: #6f6a49;
$shine: #e9f4ff;

.fire {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.show-video {
    opacity: 0;
    animation: 3s ease-in-out forwards fadeInVideo;
    z-index: 0;
  }
  &.hide-video {
    opacity: 0;
    z-index: -1;
  }
}

.revealed-hero {
  z-index: 10;
  max-width: 300px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  box-shadow: 0 0 6px 5px #00000056;
  animation-delay: 10s;
  filter: grayscale(100%);
  animation: 5s cubic-bezier(0.005, 0.765, 0.34, 0.99) forwards reveal,
    10s cubic-bezier(1, 0.01, 1, -0.085) forwards colorFade;
  &:before {
    content: "";
    z-index: 12;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    animation: 10s linear forwards blur;
  }

  .image {
    width: 300px;
    height: 300px;
    background-color: #414141;
    img {
      width: 100%;
    }
  }
  .meta {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 2px;
    background-color: #414141;
    font-family: "Lato", sans-serif;

    &.name {
      grid-template-columns: 1fr;
      padding: 15px 0;
      font-weight: bold;
    }
    span {
      padding: 10px 5px;
      margin-bottom: 2px;
      display: block;
      background-color: #000;
      &.value {
        font-weight: bold;
        text-transform: uppercase;
      }
      &.neophyte {
        background-color: #1dec46;
        color: #000;
      }
      &.apprentice {
        background-color: #fff;
        color: #000;
      }
      &.master {
        background-color: #0faef4;
        color: #000;
      }
      &.grandmaster {
        background-color: #d3070e;
        color: #fff;
      }
      &.legendary {
        background-color: #fbc913;
        color: #000;
      }
    }
  }
}
.controls {
  width: 420px;
  position: absolute;
  top: 77%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding-top: 40px;
  .congrats-header {
    z-index: 2;
    transition: opacity 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 12s;
    opacity: 0;
    h4 {
      font-size: 1.3rem;
      width: 80%;
      margin: 1.2rem auto;
    }
    /* position: absolute;
  top: 20px;
  left: 50%; */
  }
  .buttons {
    transition: opacity 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 12s;
    opacity: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}
.wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;

  .connected-as {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0 0 1rem;
    display: block;
  }
  img.logo {
    width: 300px;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    top: 30%;
    position: absolute;
    left: 50%;
    max-width: 500px;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 576px) {
      width: 90%;
    }
  }
  .hide {
    display: none;
  }
  .reveal-button {
    top: 72%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.reveal-container {
  background: none;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  outline: 0;
  position: absolute;
  /* transform-origin: 0% 100%; */
  transition: transform 50ms ease-in-out;
  width: 20rem;
  -webkit-tap-highlight-color: transparent;
  .count {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    top: -20px;
    position: relative;
  }
  &:active {
    transform: rotate(4deg);
  }
  // Button was clicked
  &.clicked {
    animation: 150ms ease-in-out 1 shake;
    pointer-events: none;
    .reveal-container__text {
      opacity: 0;
      transition: opacity 100ms linear 200ms;
    }
    &::before {
      // background/bar
      height: 1rem;
      width: 60%;
      opacity: 1;
    }
    .coin {
      transition: margin-bottom 1s linear 200ms;
      margin-bottom: 0;
    }
  }
  // Coin almost finished falling
  &.shrink-landing {
    &::before {
      // background/bar
      transition: width 200ms ease-in;
      width: 0;
    }
  }
  // Coin finished falling
  &.coin-landed {
    &::after {
      // Thank you message
      opacity: 1;
      transform: scale(1);
      transform-origin: 50% 100%;
    }

    // Make the little confetti looking dots on this wrapper
    .coin-wrapper {
      background: radial-gradient(
          circle at 35% 97%,
          rgba($bg-button, 0.4) 0.04rem,
          transparent 0.04rem
        ),
        radial-gradient(
          circle at 45% 92%,
          rgba($bg-button, 0.4) 0.04rem,
          transparent 0.02rem
        ),
        radial-gradient(
          circle at 55% 98%,
          rgba($bg-button, 0.4) 0.04rem,
          transparent 0.04rem
        ),
        radial-gradient(
          circle at 65% 96%,
          rgba($bg-button, 0.4) 0.06rem,
          transparent 0.06rem
        );
      background-position: center bottom;
      background-size: 100%;
      bottom: -1rem;
      opacity: 0;
      transform: scale(2) translateY(-10px);
    }
  }

  &__text {
    color: $font-color;
    margin-right: 1.8rem;
    opacity: 1;
    position: relative;
    transition: opacity 100ms linear 500ms;
    z-index: 3;
  }

  // Background of button
  &::before {
    background: $bg-button;
    border-radius: 90%;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: height 250ms ease-in-out 400ms, width 250ms ease-in-out 300ms;
    width: 100%;
    z-index: 2;
    opacity: 0;
  }
}

.coin-wrapper {
  background: none;
  bottom: 0;
  height: 18rem;
  left: 0;
  opacity: 1;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transform: none;
  transform-origin: 50% 100%;
  transition: opacity 200ms linear 100ms, transform 300ms ease-out;
  width: 100%;
  height: 100vh;
}

.bounce {
  animation: 5s ease-in-out infinite bounce;
}
.coin {
  --front-y-multiplier: 0;
  --back-y-multiplier: 0;
  --coin-y-multiplier: 0;
  --coin-x-multiplier: 0;
  --coin-scale-multiplier: 0;
  --coin-rotation-multiplier: 0;
  --shine-opacity-multiplier: 0.2;
  --shine-bg-multiplier: 50%;
  bottom: calc(var(--coin-y-multiplier) * 1rem - 3rem);
  height: $coin-size;
  margin-bottom: 3.05rem;
  position: absolute;
  right: calc(var(--coin-x-multiplier) * 35% + 50%);
  transform: translateX(50%) scale(calc(0.4 + var(--coin-scale-multiplier)))
    rotate(calc(var(--coin-rotation-multiplier) * -1deg));
  transition: opacity 100ms linear 200ms;
  width: $coin-size;
  z-index: 3;

  &__front,
  &__middle,
  &__back,
  &::before,
  &__front::after,
  &__back::after {
    border-radius: 50%;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
  }

  // Tails
  &__front {
    background-image: url("../../assets/mint-token.png");
    background-size: cover;

    background-color: $c-d;
    background-size: 100% 100%;
    transform: translateY(
        calc(var(--front-y-multiplier) * #{$coin-thickness} / 2)
      )
      scaleY(var(--front-scale-multiplier));

    // Shadow on coin face
    &::after {
      background: rgba(#000, 0.2);
      content: "";
      opacity: var(--front-y-multiplier);
    }
  }

  &__middle {
    background: $c-side;
    transform: translateY(
        calc(var(--middle-y-multiplier) * #{$coin-thickness} / 2)
      )
      scaleY(var(--middle-scale-multiplier));
  }

  // Heads
  &__back {
    background-image: url("../../assets/mint-token.png");
    background-size: cover;
    /* background: radial-gradient(
        circle at 50% 50%,
        transparent 50%,
        rgba($c-side, 0.4) 54%,
        $c-m 54%
      ),
      radial-gradient(circle at 50% 40%, $c-l 23%, transparent 23%),
      radial-gradient(circle at 50% 100%, $c-l 35%, transparent 35%); */
    background-color: $c-d;
    background-size: 100% 100%;
    transform: translateY(
        calc(var(--back-y-multiplier) * #{$coin-thickness} / 2)
      )
      scaleY(var(--back-scale-multiplier));

    // Shadow on coin face
    &::after {
      background: rgba(#000, 0.2);
      content: "";
      opacity: var(--back-y-multiplier);
    }
  }

  // Light glare on the coin
  &::before {
    background: radial-gradient(
        circle at 25% 65%,
        transparent 50%,
        rgba(white, 0.9) 90%
      ),
      linear-gradient(
        55deg,
        transparent calc(var(--shine-bg-multiplier) + 0%),
        $shine calc(var(--shine-bg-multiplier) + 0%),
        transparent calc(var(--shine-bg-multiplier) + 50%)
      );
    content: "";
    opacity: var(--shine-opacity-multiplier);
    transform: translateY(
        calc(var(--middle-y-multiplier) * #{$coin-thickness} / -2)
      )
      scaleY(var(--middle-scale-multiplier))
      rotate(calc(var(--coin-rotation-multiplier) * 1deg));
    z-index: 10;
  }

  // Sqaure for the 'side' of the coin
  &::after {
    background: $c-side;
    content: "";
    height: $coin-thickness;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
  }
}

@keyframes shake {
  0% {
    transform: translate(-50%, -50%) rotate(4deg);
  }
  66% {
    transform: translate(-50%, -50%) rotate(-4deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate();
  }
}
/* @keyframes reveal {
  0% {
    transform: translate3d(-50%, 100%, 0);
  }
  58% {
    transform: translate3d(-50%, -30%, 0);
  }
  73% {
    transform: translate3d(-50%, -40%, 0);
  }
  88% {
    transform: translate3d(-50%, -50%, 0);
  }
} */
@keyframes reveal {
  from {
    transform: translate(-50%, 100%) scale(0.1);
    filter: grayscale(100%);
  }
  to {
    transform: translate(-50%, -60%) scale(1);
    filter: grayscale(0%);
  }
}
@keyframes colorFade {
  from {
    filter: grayscale(100%);
  }
  to {
    filter: grayscale(0%);
  }
}
@keyframes blur {
  100% {
    backdrop-filter: blur(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInVideo {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(-8px);
  }
}
</style>
